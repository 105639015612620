<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-layout wrap pt-5 px-5>
      <v-layout wrap>
        <v-flex xs12 text-left>
          <span style="color: #000; font-family: Montserrat-Bold; font-size: 25px"
            >User View</span
          >
        </v-flex>
      </v-layout>
      <v-flex xs12 pt-5>
        <v-card>
          <v-layout wrap justify-start>
            <v-flex xs6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Name
                  </span>
                </v-flex>
                <v-flex xs8 text-left v-if="user.name">
                  <span style="color: black; font-family: poppinsmedium"
                    >{{ user.name }}
                  </span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Email
                  </span>
                </v-flex>
                <v-flex xs8 text-left v-if="user.email">
                  <span style="color: black; font-family: poppinsmedium"
                    >{{ user.email }}
                  </span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs6 px-5 py-5 v-if="user.phone">
              <v-layout wrap>
                <v-flex xs2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Phone
                  </span>
                </v-flex>
                <v-flex xs8 text-left v-if="user.phone">
                  <span style="color: black; font-family: poppinsmedium"
                    >{{ user.countryCode }} {{ user.phone }}
                  </span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Role
                  </span>
                </v-flex>
                <v-flex xs3 text-left v-if="user.role">
                  <span style="color: black; font-family: poppinsmedium">{{ user.role }} </span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Date
                  </span>
                </v-flex>
                <v-flex xs8 text-left v-if="user.create_date">
                  <span style="color: black; font-family: poppinsmedium"
                    >{{ user.create_date.slice(0, 10) }}
                  </span>
                </v-flex>
              </v-layout>
              
            </v-flex>
            <v-flex xs6 px-5 py-5>
              <v-layout wrap>
                <v-flex xs2 text-left>
                  <span style="color: black; font-family: Montserrat-Regular; font-weight: 600;"
                    >Sign-in
                  </span>
                </v-flex>
                <v-flex xs3 text-left v-if="user.role">
                  <span style="color: black; font-family: poppinsmedium">{{ user.signUpMethod }} </span>
                </v-flex>
              </v-layout>
              
            </v-flex>
          </v-layout>
          <!-- <v-layout wrap pt-5 px-5 v-if="course.length>0">
            <v-flex xs12>
              <span
                style="color: #000; font-family: Montserrat-Bold; font-size: 20px"
                >Courses</span
              >
              <v-layout wrap justify-center>
                <v-flex xs12 py-4 v-for="(item, i) in course" :key="i">
                  <v-card outlined>
                    <v-layout wrap justify-center pa-4>
                      <v-flex xs12 sm4 md2>
                        <v-img
                          :src="mediaURL + item.courseId.frontImage"
                        ></v-img>
                      </v-flex>
                      <v-flex xs12 sm8 md8 pa-4 text-left>
                        <v-layout wrap>
                          <v-flex xs12>
                            <span
                              style="
                                color: #7f7f7f;
                                font-family: poppinsregular;
                                font-size: 13px;
                              "
                              >Courses | {{ item.courseId.category.name }}</span
                            >
                          </v-flex>
                          <v-flex xs12 pt-1>
                            <span
                              style="
                                font-family: Montserrat-Regular; font-weight: 600;;
                                font-size: 14px;
                                color: #000000;
                              "
                              >{{ item.courseId.courseName }}</span
                            >
                          </v-flex>
                          <v-flex xs12 pt-2>
                            <span
                              style="
                                font-family: poppinsmedium;
                                font-size: 12px;
                                color: #000000;
                              "
                              v-html="item.courseId.description"
                            ></span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm2 md2 text-right align-self-center>
                        <v-btn color="#3CB043" dark :to="'/examData?userId=' + user._id"><span style="color:white">Exam Details</span></v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      user: {},
      course: [],
      id: this.$route.query.id,
    };
  },
  mounted() {
    this.getData(this.id);
  },
  methods: {
    getData(id) {
      this.appLoading = true;
      axios({
        url: '/admin/user/view/' + id,
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>